import React, { useState, useEffect, useCallback } from 'react';
import {
  Box,
  Container,
  makeStyles,
  CircularProgress,
  SvgIcon,
  List,
  Button,
  Typography,
  withStyles,
  ListSubheader,
  Divider
} from '@material-ui/core';
import Page from 'src/components/Page';
import useIsMountedRef from 'src/hooks/useIsMountedRef';
import PerfectScrollbar from 'react-perfect-scrollbar';
import useAuth from 'src/hooks/useAuth';
import gql from 'graphql-tag';
import { client } from 'src/utils/GQLClient';
import PeopleOutlineRoundedIcon from '@material-ui/icons/PeopleOutlineRounded';
import { PlusCircle as PlusCircleIcon } from 'react-feather';
import Header from './Header';
import LabelItem from './NavItem';
import PropTypes from 'prop-types';
import './App.css';
import { useLocation, matchPath } from 'react-router-dom';

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: '#FFFF',
    display: 'flex',
    minHeight: '100%',
    overflow: 'hidden',
    width: '100%'

    //paddingBottom: theme.spacing(3)
  },
  divider: {
    width: 1,

    position: 'fixed'
  },
  wrapper: {
    display: 'flex',
    // flex: '1 1 auto',
    // overflow: 'hidden',
    height: '100%',
    paddingLeft: 256
  },
  contentContainer: {
    flex: '1 1 auto',
    overflow: 'hidden'
  },

  sticky: {
    position: 'fixed',
    top: 0,
    width: '100%'
  },
  sticky: {
    position: 'fixed',
    top: 64,
    left: 256,
    right: 0,
    zIndex: 1000,
    backgroundColor: '#fff'
  },
  sticky1: {
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    zIndex: 1000,
    backgroundColor: '#fff'
  }
}));

const GreenButton = withStyles({
  root: {
    backgroundColor: '#33691e',
    color: 'white',
    '&:hover': {
      backgroundColor: '#33691e'
    }
  }
})(Button);

const Applications = ({ children }) => {
  const classes = useStyles();
  const isMountedRef = useIsMountedRef();
  const { institution } = useAuth();
  const { user } = useAuth();

  // const fixedText = "Applications";
  // const whenNotFixed = "Applications";
  // const [headerText, setHeaderText] = useState(whenNotFixed);
  // useEffect(() => {
  //   const header = document.getElementById("myHeader");
  //   const sticky = header.offsetTop + 64;
  //   const scrollCallBack = window.addEventListener("scroll", () => {
  //     if (window.pageYOffset > sticky) {
  //       header.classList.add("sticky");

  //     } else {
  //       header.classList.remove("sticky");

  //     }
  //   });
  //   console.log(sticky)
  //   return () => {
  //     window.removeEventListener("scroll", scrollCallBack);
  //   };
  // }, []);

  const labels = [
    {
      id: 'programs',
      type: 'Programs',
      name: 'Programs'
    },
    {
      id: 'quota',
      type: 'Quota',
      name: 'Quota',
      unreadCount: 1,
      totalCount: 0
    },
    {
      id: 'stream',
      type: 'Stream',
      name: 'Stream',
      unreadCount: 0,
      totalCount: 0
    },
    {
      id: 'batch',
      type: 'Batch',
      name: 'Batch',
      unreadCount: 0,
      totalCount: 0
    },

    {
      id: 'academicyear',
      type: 'Academic Year',
      name: 'Academic Year',
      unreadCount: 0,
      totalCount: 1
    }
  ];

  const Users = {
    items: [
      {
        title: 'General Settings',
        href: '/app/Setting/GeneralSettings/:id',
        icon: 'fluent:settings-24-regular',
        items: [
          {
            title: 'Personal',
            href: '/app/Setting/GeneralSettings/Personal',
          },
          {
            title: 'Company',
            href: '/app/Setting/GeneralSettings/Company',
          },
          {
            title: 'Notification Settings',
            href: '/app/Setting/GeneralSettings/NotificationSettings',
          }
        ]
      },
    
      {
        title: 'User Management',
        href: '/app/Setting/Usermanagement',
        icon: 'iconoir:profile-circle',
        items: [
          {
            title: 'Users',
            href: '/app/Setting/Usermanagement/Users/UsersDetails',
          },
          {
            title: 'Access Control',
            href: '/app/Setting/Usermanagement/AccessControll/Details',
            info:'iconoir:lock'
          }
        ]
      },
      {
        title: 'Template Settings',
        href: '/app/settings',
        icon: 'iconoir:send-diagonal',
        items: [
          {
            title: 'Email',
            href: '/app/settings/EmailTemplete',
          },
          {
            title: 'Notification SMS',
            href: '/app/settings/SMSTemplete',
          },
          {
            title: 'Whatsapp',
            href: '/app/settings/WhatsappTemplete',
          },
         
        ]
      },
      {
        title: 'Integration',
        href: '/app/Setting/Integration/IntegrationDetails',
        icon: 'bx:buildings',
       
      },
      {
        title: 'Data Management',
        href: '/app/Setting/DataManagement',
        icon: 'majesticons:data-plus-line',
        items: [
          {
            title: 'Import Data',
            href: '/app/Setting/DataManagement/ImportDetails',
          },
          {
            title: 'Backup Data',
            href: '/app/Setting/DataManagement/BackupDetails',
          },
          {
            title: 'Export Data',
            href: '/app/Setting/DataManagement/ExportDetails',
          },
          {
            title: 'Activity log',
            href: '/app/Setting/DataManagement/log',
            info:'iconoir:lock'
          },
          {
            title: 'Archive',
            href: '/app/Setting/DataManagement/Archive',
            info:'iconoir:lock'
          }
        ]
      },
      {
        title: 'Compliance',
        href: '/app/Setting/Compliance',
        icon: 'teenyicons:clipboard-tick-outline',
        items: [
          {
            title: 'GDPR',
            href: '/app/Setting/Compliance/GDPR',
          },
         
        ]
      },
    
    ]
  };

  function renderNavItems({ items, pathname, depth = 0 }) {
    return (
      <List disablePadding>
        {items.reduce(
          (acc, item) => reduceChildRoutes({ acc, item, pathname, depth }),
          []
        )}
      </List>
    );
  }

  function reduceChildRoutes({ acc, pathname, item, depth }) {
    const key = item.title + depth;

    if (item.items) {
      const open = matchPath(pathname, {
        path: item.href,
        exact: false
      });

      acc.push(
        <LabelItem
          depth={depth}
          icon={item.icon}
          info={item.info}
          key={key}
          open={Boolean(open)}
          title={item.title}
        >
          {renderNavItems({
            depth: depth + 1,
            pathname,
            items: item.items
            
          })}
        </LabelItem>
      );
    } else {
      acc.push(
        <LabelItem
          depth={depth}
          href={item.href}
          icon={item.icon}
          info={item.info}
          key={key}
          title={item.title}
        />
      );
    }

    return acc;
  }

  const Drawer = window.localStorage.getItem('drawer');
  const location = useLocation();

  const [height, setHeight] = useState(0);

  const measuredRef = useCallback(node => {
    if (node !== null) {
      setHeight(node.getBoundingClientRect().height);
    }
  }, []);
  console.log(height);

  const [d, setD] = useState(Drawer);
  window.addEventListener('storage', () => {
    // When local storage changes, dump the list to
    // the console.
    setD(window.localStorage.getItem('drawer'));

    console.log(JSON.parse(window.localStorage.getItem('drawer')));
  });

  console.log(d);
  const content = (
    <Box height="100%" display="flex" flexDirection="column">
      <PerfectScrollbar options={{ suppressScrollX: true }}>
        <Divider />
        <Box p={2}>
          <List
            key={Users.subheader}
            subheader={
              <ListSubheader disableGutters disableSticky>
                {Users.subheader}
              </ListSubheader>
            }
          >
            {renderNavItems({
              items: Users.items,
              pathname: location.pathname
            })}
          </List>
        </Box>
        <Divider />
      </PerfectScrollbar>

      <Box flexGrow={1} />

    </Box>
  );
  return (
    <Page title="Applications">
       


      <Box>
        <Box
          height="100%"
          display="flex"
          flexDirection="column"
          minWidth={256}
          mt={8}
          position="fixed"
          style={{ borderColor: '#bdbdbd',backgroundColor:'#e0e0e0' }}
          borderRight={1}
        >
          <PerfectScrollbar options={{ suppressScrollX: true }}>
            <List disablePadding>{content}</List>
          </PerfectScrollbar>
        </Box>

        
        <div className={classes.wrapper}>
          <div className={classes.contentContainer}>
            <div className={classes.content}>{children}</div>
          </div>
        </div>
  
      </Box>
    </Page>
  );
};

Applications.propTypes = {
  children: PropTypes.node
};
export default Applications;
