import React from 'react';
import { makeStyles ,Typography} from '@material-ui/core';
import Page from 'src/components/Page';

const useStyles = makeStyles(() => ({
  root: {}
}));

const HomeView = () => {
  const classes = useStyles();

  return <Page className={classes.root} title="Home">

  </Page>;
};

export default HomeView;
