import React from 'react';
import {
  Box,
  CircularProgress,
  useMediaQuery,
  useTheme,
  makeStyles
} from '@material-ui/core';
import RoundDialog from 'src/components/Dialog/RoundDialog';
import Hour from './CircularLoader.gif';
import Dialog from '@mui/material/Dialog';
import Backdrop from '@mui/material/Backdrop';
import { alpha } from '@mui/material/styles';
import Typography from 'src/theme/typography';
const useStyles = makeStyles(theme => ({
  root: {
    alignItems: 'center',
    backgroundColor: theme.palette.background.dark,
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    justifyContent: 'center',
    left: 0,
    padding: theme.spacing(3),
    position: 'fixed',
    top: 0,
    width: '100%'
    // zIndex: 2000
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
    backgroundColor: alpha(theme.palette.background.default, 0.5)
  }
}));

const Loader = ({ handleClose, open, text }) => {
  const classes = useStyles();
  const theme = useTheme();

  const mobileDevice = useMediaQuery(theme.breakpoints.down('xs'));

  return (
    <Box style={{backgroundColor:'transparent'}} maxWidth="xs" onClose={handleClose} open={open} 
    BackdropComponent={Backdrop}>
    <div style={{ backgroundColor: 'transparent', outline: 'none' }}>
      <Backdrop className={classes.backdrop} open={open}>
      {mobileDevice ? (
        <Box
          justifyContent="center"
          m={3}
          display="flex"
          justifyItems="center"
          padding="5px"
          mb={text ? 1 : 3}
        >
          <img src={Hour} height={40} width={40} />
        </Box>
      ) : (
        <Box
          justifyContent="center"
          m={5}
          display="flex"
          justifyItems="center"
          padding="5px"
          mb={text ? 1 : 5}
        >
          <img src={Hour} height={40} width={40} />
        </Box>
      )}
      {/* {text && (
        <Box padding="2px" mx={2} mb={4}>
          {text}
        </Box>
      )} */}
      </Backdrop>
      </div>
      
    </Box>
  );
};

export default Loader;
