import React, { useState , useRef } from 'react';
import { NavLink as RouterLink } from 'react-router-dom';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { Button, Collapse, ListItem, makeStyles, Typography, Menu } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import { Icon } from '@iconify/react';
import { Box } from '@mui/material';

const useStyles = makeStyles(theme => ({
  item: {
    display: 'block',
    paddingTop: 0,
    paddingBottom: 0
  },
  itemLeaf: {
    display: 'flex',
    paddingTop: 0,
    paddingBottom: 0,
    flexDirection : 'column'
  },
  button: {
    color: '#ffffff',
    padding: '10px 8px',
    justifyContent: 'center',
    textTransform: 'none',
    letterSpacing: 0,
    width: '100%'
  },
  buttonLeaf: {
    color: '#ffffff',
    padding: '10px',
    justifyContent: 'center',
    textTransform: 'none',
    letterSpacing: 0,
    width: '100%',
    fontWeight: theme.typography.fontWeightRegular,
    '&:hover': {
      backgroundColor: '#003780', // Add your desired hover styles here
    },
    '&.depth-0': {
      '& $title': {
        // fontWeight: theme.typography.fontWeightMedium
      }
    }
  },
  icon: {
    display: 'flex',
    alignItems: 'center',
    marginRight: theme.spacing(3)
  },
  // title: {
  //   marginRight: 'auto'
  // },
  active: {
    color: '#ffffff',
    '& $title': {
      // fontWeight: theme.typography.fontWeightMedium
    },
    '& $icon': {
      color: '#ffffff'
    },
    backgroundColor: '#003780',
  }
}));

const NavItem = ({
  children,
  className,
  depth,
  href,
  icon: NavIcon,
  info: Info,
  open: openProp,
  title,
  handleDrawer,
  ...rest
}) => {
  const classes = useStyles();
  const [open, setOpen] = useState(openProp);
  const ref = useRef(null);

  const handleToggle = () => {
    setOpen(prevOpen => !prevOpen);
  };

  let paddingLeft = 8;

  if (depth > 0) {
    paddingLeft = 32 + 8 * depth;
  }

  const style = { paddingLeft };

  if (children) {
    return (
      <ListItem
        className={clsx(classes.item, className)}
        disableGutters
        key={title}
        {...rest}
      >
        <Button className={classes.button} onClick={handleToggle} >
        {NavIcon && (
            <Icon
              icon={NavIcon}
              style={{
                color:'#ffffff',                fontSize: '25px',
                //marginRight: '2px',
                marginBottom: '2px'
              }}
            />
          )}
          {/* <Typography variant="body2" className={classes.title} >
            {title}
          </Typography> */}
          {open ? <ExpandLessIcon /> :
          <ExpandMoreIcon />}
        </Button>
        <Collapse in={open}>{children}</Collapse>
      </ListItem>
    );
  }

  return (
    <ListItem
      className={clsx(classes.itemLeaf, className)}
      disableGutters
      key={title}
      {...rest}
    >
      <Button
        activeClassName={classes.active}
        className={classes.buttonLeaf}
        component={RouterLink}
        onClick={title == 'Academic Setup' && handleDrawer}
        exact
        
        to={href}
      >
         {NavIcon && (
            <Icon
              icon={NavIcon}
              style={{
                color:'#ffffff',                fontSize: '25px',
                //marginRight: '10px',
                marginBottom: '2px'
              }}
            />
          )}
          {/* <Typography variant="subtitle" className={classes.title} >
            {title}
          </Typography> */}
        {Info && <Info />}
      </Button>
    </ListItem>
  );
};

NavItem.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  depth: PropTypes.number.isRequired,
  href: PropTypes.string,
  icon: PropTypes.elementType,
  info: PropTypes.elementType,
  open: PropTypes.bool,
  title: PropTypes.string.isRequired
};

NavItem.defaultProps = {
  open: false
};

export default NavItem;
