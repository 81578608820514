import React, {
  useRef,
  useState,
  useEffect
} from 'react';
import { Link as RouterLink } from 'react-router-dom';
import {
  Avatar,
  Box,
  Button,
  IconButton,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Popover,
  SvgIcon,
  Tooltip,
  Typography,
  makeStyles
} from '@material-ui/core';
import {
  Settings as SettingsIcon,
  Package as PackageIcon,
  MessageCircle as MessageIcon,
  Truck as TruckIcon
} from 'react-feather';
import { useDispatch, useSelector } from 'src/store';
import { getNotifications } from 'src/slices/notification';
import { useHistory } from 'react-router-dom';

const iconsMap = {
  order_placed: PackageIcon,
  new_message: MessageIcon,
  item_shipped: TruckIcon
};

const useStyles = makeStyles((theme) => ({
  popover: {
    width: 320
  },
  icon: {
    backgroundColor: theme.palette.secondary.main,
    color: theme.palette.secondary.contrastText
  }
}));

const Notifications = () => {
  const classes = useStyles();
  const history = useHistory();

  const { notifications } = useSelector((state) => state.notifications);
  const ref = useRef(null);
  const dispatch = useDispatch();
  const [isOpen, setOpen] = useState(false);

  const handleOpen = () => {
   
      history.push(`/app/settings`);
    
  };

  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    dispatch(getNotifications());
  }, [dispatch]);

  return (
    <>
      <Tooltip title="Settings" placement="bottom" arrow>
        <IconButton
          color="inherit"
          ref={ref}
          component={RouterLink}
          to={{
            pathname: `/app/Setting/GeneralSettings/Personal`
          }}
          color = "black"
        >
          <SvgIcon>
            <SettingsIcon />
          </SvgIcon>
        </IconButton>
      </Tooltip>
    
    </>
  );
};

export default Notifications;
