/* eslint-disable no-use-before-define */
import React, { useState, useEffect, useCallback, useRef } from 'react';
import { useLocation, matchPath } from 'react-router-dom';
import Logo from 'src/components/Logo';
import PerfectScrollbar from 'react-perfect-scrollbar';
import PropTypes from 'prop-types';
import useAuth from '../../../hooks/useAuth';
import CssBaseline from '@material-ui/core/CssBaseline';
import clsx from 'clsx';
import MenuOpenIcon from '@material-ui/icons/MenuOpen';
import { Link as RouterLink } from 'react-router-dom';
import Contacts from './Contacts';

import {
  Avatar,
  Box,
  Divider,
  Drawer,
  Chip,
  Hidden,
  AppBar,
  Toolbar,
  IconButton,
  Link,
  List,
  ListSubheader,
  Typography,
  makeStyles,
  withStyles
} from '@material-ui/core';
import Account from './Account';

import Settings from './Settings';
import { styled, useTheme } from '@mui/material/styles';
import Tooltip from '@mui/material/Tooltip';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import NavItem from './NavItem';
import Label from 'src/components/Label';
import Notifications from './Notifications';
import MenuIcon from '@material-ui/icons/Menu';
import AWS from 'aws-sdk';
import gql from 'graphql-tag';
import useIsMountedRef from 'src/hooks/useIsMountedRef';
import Calender from './Calender';
import { Icon } from '@iconify/react';
import {
  useDashboardContext,
  DashboardProvider
} from 'src/contexts/DashboardContext';
import NavIconItem from './NavIconItem';

const GreenStyleChip = withStyles({
  root: {
    backgroundColor: '#B5B2F6',
    color: 'white'
  }
})(Chip);
const ColorAppBar = withStyles({
  root: {
    backgroundColor: '#FFFFFF'
  }
})(AppBar);
const Users = {
  items: [
    {
      title: 'Dashboard',
      href: '/app/admin/home',
      icon: 'mdi-light:view-dashboard'
    },
    {
      title: 'Candidates',
      href: '/app/admin/Candidate',
      icon: 'fluent:people-list-28-regular'
    },
    {
      title: 'Jobs',
      href: '/app/admins/Job',
      icon: 'ph:suitcase-light'
    },
    {
      title: 'Clients',
      href: '/app/admin/Client',
      icon: 'fluent:building-multiple-24-regular'
    },

    {
      title: 'Work Flow - Job',
      href: '/app/admins/WorkFlow',
      icon: 'carbon:flow-data'
    },
    {
      title: 'Work Flow - Gen',
      href: '/app/admins/WorkFlowGen',
      icon: 'carbon:flow-data'
    },

    // {
    //   title: 'Integration',
    //   href: '/app/admin/user_management',
    //   icon: 'iconoir:download'
    // },
    ,
    {
      title: 'Notifications',
      href: '/app/notify/NotificationsNew',
      icon: 'ion:notifications-outline'
    },
    {
      title: 'Reports',
      href: '/app/admin/reports',
      icon: 'mdi:report-box-multiple-outline'
    }
  ]
};

const openedMixin = theme => ({
  width: drawerWidth,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen
  }),
  overflowX: 'hidden'
});
// const handleDrawer = () => {
//   if (open) {
//     setOpen(false);
//     window.localStorage.setItem('drawer', false);
//   } else {
//     setOpen(true);
//     window.localStorage.setItem('drawer', true);
//   }
// };
// console.log("opennnnnn" , open)
window.addEventListener('storage', function(e) {
  // console.log('storage fired');
  // this.fetchData();
});
const closedMixin = theme => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen
  }),
  overflowX: 'hidden',
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up('sm')]: {
    width: `calc(${theme.spacing(8)} + 1px)`
  }
});
const drawerWidth = 240;
const Drawer1 = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar
}));

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    paddingLeft: 90,
    transitionDuration: 500
  },
  root1: {
    display: 'flex',
    paddingLeft: 256,
    transitionDuration: 500
  },
  mobileDrawer: {
    width: 90,
    //top: 64,
    transitionDuration: 500,

    backgroundColor: '#002250',
    color: '#ffffff',
    height: '100%'
  },
  desktopDrawer: {
    width: 256,
    transitionDuration: 500,

    // top: 64,
    height: '100%',
    backgroundColor: '#002250',
    color: '#ffffff'
  },

  appBar: {
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.sharp,
      duration: {
        enteringScreen: 225,
        leavingScreen: 195
      }
    })
  },

  menuButton: {
    marginRight: theme.spacing(2),
    color: '#ffffff'
  },
  hide: {
    display: 'none'
  },

  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    })
  },

  contentShift: {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen
    }),
    marginLeft: 0
  },
  action: {
    marginBottom: theme.spacing(1)
  }
}));

const TopBar = ({ className, onMobileNavOpen, ...rest }) => {
  const classes = useStyles();
  const { open, setOpen } = useDashboardContext();

  const location = useLocation();
  const { user } = useAuth();
  console.log(open);
  let users = '';
  const isMountedRef = useIsMountedRef();
  window.dispatchEvent(new Event('storage'));
  const [image, setImage] = useState(null);

  const [file, setFile] = React.useState([]);
  const arrayBufferToBase64 = buffer => {
    var binary = '';
    var bytes = [].slice.call(new Uint8Array(buffer));
    bytes.forEach(b => (binary += String.fromCharCode(b)));
    return window.btoa(binary);
  };
  function renderNavItems({ items, pathname, depth = 0 }) {
    return (
      <>
        {open ? (
          <List disablePadding>
            {items.reduce(
              (acc, item) => reduceChildRoutes({ acc, item, pathname, depth }),
              []
            )}
          </List>
        ) : (
          <List disablePadding>
            {items.reduce(
              (acc, item) =>
                reduceChildIconRoutes({ acc, item, pathname, depth }),
              []
            )}
          </List>
        )}
      </>
    );
  }

  function reduceChildRoutes({ acc, pathname, item, depth }) {
    const key = item.title + depth;

    if (item.items) {
      const open = matchPath(pathname, {
        path: `${item.href}/*`,
        exact: false
      });

      acc.push(
        <NavItem
          depth={depth}
          icon={item.icon}
          info={item.info}
          key={key}
          open={Boolean(open)}
          title={item.title}
          handleDrawer={handleDrawer}
        >
          {renderNavItems({
            depth: depth + 1,
            pathname,
            items: item.items
          })}
        </NavItem>
      );
    } else {
      acc.push(
        <NavItem
          depth={depth}
          href={item.href}
          icon={item.icon}
          info={item.info}
          key={key}
          title={item.title}
          handleDrawer={handleDrawer}
        />
      );
    }

    return acc;
  }
  function reduceChildIconRoutes({ acc, pathname, item, depth }) {
    const key = item.title + depth;

    if (item.items) {
      const open = matchPath(pathname, {
        path: `${item.href}/*`,
        exact: false
      });

      acc.push(
        <NavIconItem
          depth={depth}
          icon={item.icon}
          info={item.info}
          key={key}
          open={Boolean(open)}
          title={item.title}
          handleDrawer={handleDrawer}
        >
          {renderNavItems({
            depth: depth + 1,
            pathname,
            items: item.items
          })}
        </NavIconItem>
      );
    } else {
      acc.push(
        <NavIconItem
          depth={depth}
          href={item.href}
          icon={item.icon}
          info={item.info}
          key={key}
          title={item.title}
          handleDrawer={handleDrawer}
        />
      );
    }

    return acc;
  }
  const [openSelection, setOpenSelection] = useState(false);
  const handleSelectionOpen = () => {
    setOpenSelection(true);
  };
  const handleSelectionClose = () => {
    setOpenSelection(false);
  };

  const [openSubmission, setOpenSubmission] = useState(false);
  const handleSubmissionOpen = () => {
    setOpenSubmission(true);
  };
  const handleSubmissionClose = () => {
    setOpenSubmission(false);
  };

  const [openInterview, setOpenInterview] = useState(false);
  const handleInterviewOpen = () => {
    setOpenInterview(true);
  };
  const handleInterviewClose = () => {
    setOpenInterview(false);
  };

  const [openPlacement, setOpenPlacement] = useState(false);
  const handlePlacementOpen = () => {
    setOpenPlacement(true);
  };
  const handlePlacementClose = () => {
    setOpenPlacement(false);
  };

  const handleDrawer = () => {
    if (open) {
      setOpen(false);
      window.localStorage.setItem('drawer', false);
    } else {
      setOpen(true);
      window.localStorage.setItem('drawer', true);
    }
  };
  const refSelection = useRef(null);
  const refSubmission = useRef(null);
  const refInterview = useRef(null);
  const refPlacement = useRef(null);

  const content = (
    <Box height="100%" display="flex" flexDirection="column">
      <PerfectScrollbar options={{ suppressScrollX: true }}>
        <Box pt={2.5} pb={1.4} px={1.5}>
          <Box mt={-1} ml={1.2} display="flex">
            {open === true && (
              <IconButton
                color="inherit"
                size="small"
                aria-label="open drawer"
                onClick={handleDrawer}
                edge="start"
                className={clsx(classes.menuButton, open)}
              >
                <MenuIcon fontSize="large" />
              </IconButton>
            )}
            {open === false && (
              <>
                <Box mr={1} />
                <IconButton
                  color="inherit"
                  size="small"
                  aria-label="open drawer"
                  onClick={handleDrawer}
                  edge="start"
                  className={clsx(classes.menuButton, open)}
                >
                  <MenuOpenIcon fontSize="large" />
                </IconButton>
              </>
            )}
            <Box mr={0} />
            {open && (
              <Box mt={1}>
                <Typography
                  variant="h5"
                  style={{ color: '#ffffff', fontSize: '20px' }}
                >
                  Recruit EU
                </Typography>
              </Box>
            )}
          </Box>
        </Box>{' '}
        <Divider style={{ backgroundColor: '#003780' }} />
        <Box p={2}>
          <List
            key={Users.subheader}
            subheader={
              <ListSubheader disableGutters disableSticky>
                {Users.subheader}
              </ListSubheader>
            }
          >
            {renderNavItems({
              items: Users.items,
              pathname: location.pathname
            })}
          </List>
        </Box>
        <Divider style={{ backgroundColor: '#003780' }} />
      </PerfectScrollbar>

      <Box flexGrow={1} />

      <Divider />
    </Box>
  );

  return (
    <div className={classes.root}>
      <CssBaseline />
      <ColorAppBar className={open ? classes.root1 : classes.root} {...rest}>
        <Toolbar className={classes.toolbar}>
          <Box display="flex" alignItems={'center'} style={{ width: '40%' }}>
            <Box display="flex">
              <Typography
                variant="body1"
                // color="textSecondary"
                style={{ fontWeight: 500, fontSize: '18px', color: '#757575' }}
              >
                Quick
              </Typography>
              <Box mr={1} />
              <Typography
                variant="body1"
                // color="textSecondary"
                style={{ fontSize: '18px', color: '#757575' }}
              >
                Access
              </Typography>
              <Box mr={3} />
              <Tooltip title="Selections" arrow>
                <Icon
                  icon={'mingcute:finger-tap-line'}
                  onClick={handleSelectionOpen}
                  ref={refSelection}
                  style={{
                    color: '#757575',
                    fontSize: '22px',
                    marginRight: '10px',
                    marginBottom: '2px'
                  }}
                />
              </Tooltip>

              <Box mr={2} />
              <Tooltip title="CV Submissions" arrow>
                <Icon
                  onClick={handleSubmissionOpen}
                  ref={refSubmission}
                  icon={'grommet-icons:send'}
                  style={{
                    color: '#757575',
                    fontSize: '22px',
                    marginRight: '10px',
                    marginBottom: '2px'
                  }}
                />
              </Tooltip>
            </Box>
            <Box mr={2} />
            <Tooltip title="Interviews" arrow>
              <Icon
                icon={'fluent:target-arrow-20-filled'}
                onClick={handleInterviewOpen}
                ref={refInterview}
                style={{
                  color: '#757575',
                  fontSize: '22px',
                  marginRight: '10px',
                  marginBottom: '2px'
                }}
              />
            </Tooltip>
            <Box mr={2} />
            <Tooltip title="Placements" arrow>
              <Icon
                icon={'mdi:star-circle-outline'}
                onClick={handlePlacementOpen}
                ref={refPlacement}
                style={{
                  color: '#757575',
                  fontSize: '22px',
                  marginRight: '10px',
                  marginBottom: '2px'
                }}
              />
            </Tooltip>
            <Box mr={2} />
          </Box>

          <Box ml={2} flexGrow={1} />
          <Calender />
          {/* <Contacts /> */}
          <Notifications />
          {/*<Settings /> */}
          <Settings />
          <Box ml={2}>
            <Account />
          </Box>
        </Toolbar>
      </ColorAppBar>
      <Menu
        onClose={handleSelectionClose}
        keepMounted
        // PaperProps={{ className: classes.popover }}
        getContentAnchorEl={null}
        anchorEl={refSelection.current}
        open={openSelection}
        PaperProps={{
          elevation: 5,
          sx: {
            minWidth: '180px',
            padding: '10px 18px 8px 18px',
            borderRadius: '12px',
            '& .MuiMenuItem-root': {
              borderRadius: '12px',
              // backgroundColor: '#f4f6f8',
              marginBottom: 1,
              padding: 1.5,
              minWidth: 100
            },

            overflow: 'visible',
            filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
            mt: 1.5,
            '&:before': {
              content: '""',
              display: 'block',
              position: 'absolute',
              top: 0,
              right: 173,
              width: 10,
              height: 10,
              bgcolor: 'background.paper',
              transform: 'translateY(-50%) rotate(45deg)',
              zIndex: 0
            }
          }
        }}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center'
        }}
      >
        <MenuItem
          component={RouterLink}
          to="/app/admin/Candidate/Details/4/New"
        >
          <Box display="flex" width="100%">
            <Typography variant="body2">
              Daniel Jacob / CEO / TechGenius Solutions Inc
            </Typography>
          </Box>
        </MenuItem>
        <MenuItem
          component={RouterLink}
          to="/app/admin/Candidate/Details/2/New"
        >
          <Box display="flex" width="100%">
            <Typography variant="body2">
              Prajwal G B / Intern / TechGenius Solutions Inc
            </Typography>
          </Box>
        </MenuItem>
        <MenuItem
          component={RouterLink}
          to="/app/admin/Candidate/Details/3/New"
        >
          <Box display="flex" width="100%">
            <Typography variant="body2">
              Varun Patil / CTO / TechGenius Solutions Inc
            </Typography>
          </Box>
        </MenuItem>
      </Menu>
      <Menu
        onClose={handleSubmissionClose}
        keepMounted
        // PaperProps={{ className: classes.popover }}
        getContentAnchorEl={null}
        anchorEl={refSubmission.current}
        open={openSubmission}
        PaperProps={{
          elevation: 5,
          sx: {
            minWidth: '180px',
            padding: '10px 18px 8px 18px',
            borderRadius: '12px',
            '& .MuiMenuItem-root': {
              borderRadius: '12px',
              // backgroundColor: '#f4f6f8',
              marginBottom: 1,
              padding: 1.8,
              minWidth: 100
            },

            overflow: 'visible',
            filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
            mt: 1.5,
            '&:before': {
              content: '""',
              display: 'block',
              position: 'absolute',
              top: 0,
              right: 173,
              width: 10,
              height: 10,
              bgcolor: 'background.paper',
              transform: 'translateY(-50%) rotate(45deg)',
              zIndex: 0
            }
          }
        }}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center'
        }}
      >
        <MenuItem
          component={RouterLink}
          to="/app/admin/Candidate/Details/4/New"
        >
          <Box display="flex" width="100%">
            <Typography variant="body2">
              Tarun H / Intern / InnoSys Innovations Ltd
            </Typography>
          </Box>
        </MenuItem>
        <MenuItem
          component={RouterLink}
          to="/app/admin/Candidate/Details/2/New"
        >
          <Box display="flex" width="100%">
            <Typography variant="body2">
              Vinay Kumar / CTO / TechGenius Solutions Inc
            </Typography>
          </Box>
        </MenuItem>
      </Menu>
      <Menu
        onClose={handleInterviewClose}
        keepMounted
        // PaperProps={{ className: classes.popover }}
        getContentAnchorEl={null}
        anchorEl={refInterview.current}
        open={openInterview}
        PaperProps={{
          elevation: 5,
          sx: {
            minWidth: '180px',
            padding: '10px 18px 8px 18px',
            borderRadius: '12px',
            '& .MuiMenuItem-root': {
              borderRadius: '12px',
              // backgroundColor: '#f4f6f8',
              marginBottom: 1,
              padding: 1.8,
              minWidth: 100
            },

            overflow: 'visible',
            filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
            mt: 1.5,
            '&:before': {
              content: '""',
              display: 'block',
              position: 'absolute',
              top: 0,
              right: 192,
              width: 10,
              height: 10,
              bgcolor: 'background.paper',
              transform: 'translateY(-50%) rotate(45deg)',
              zIndex: 0
            }
          }
        }}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center'
        }}
      >
        <MenuItem
          component={RouterLink}
          to="/app/admin/Candidate/Details/1/New"
        >
          <Box display="flex" width="100%">
            <Typography variant="body2">
              Tarun H / Manager / DataInnovate Solutions Pvt Ltd
            </Typography>
          </Box>
        </MenuItem>
        <MenuItem
          component={RouterLink}
          to="/app/admin/Candidate/Details/2/New"
        >
          <Box display="flex" width="100%">
            <Typography variant="body2">
              Prajwal G B / Developer / TechGenius Solutions Inc
            </Typography>
          </Box>
        </MenuItem>
        <MenuItem
          component={RouterLink}
          to="/app/admin/Candidate/Details/3/New"
        >
          <Box display="flex" width="100%">
            <Typography variant="body2">
              Vinay Kumar / CEO / InnoSys Innovations Ltd
            </Typography>
          </Box>
        </MenuItem>
      </Menu>
      <Menu
        onClose={handlePlacementClose}
        keepMounted
        // PaperProps={{ className: classes.popover }}
        getContentAnchorEl={null}
        anchorEl={refPlacement.current}
        open={openPlacement}
        PaperProps={{
          elevation: 5,
          sx: {
            minWidth: '180px',
            padding: '10px 18px 8px 18px',
            borderRadius: '12px',
            '& .MuiMenuItem-root': {
              borderRadius: '12px',
              // backgroundColor: '#f4f6f8',
              marginBottom: 1,
              padding: 1.8,
              minWidth: 100
            },

            overflow: 'visible',
            filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
            mt: 1.5,
            '&:before': {
              content: '""',
              display: 'block',
              position: 'absolute',
              top: 0,
              right: 207,
              width: 10,
              height: 10,
              bgcolor: 'background.paper',
              transform: 'translateY(-50%) rotate(45deg)',
              zIndex: 0
            }
          }
        }}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center'
        }}
      >
        <MenuItem
          component={RouterLink}
          to={`/app/admin/Candidate/Details/${`3`}/New`}
        >
          <Box display="flex" width="100%">
            <Typography variant="body2">
              Prajwal G B / Delevoper / DataInnovate Solutions Pvt Ltd
            </Typography>
          </Box>
        </MenuItem>
      </Menu>
      {open ? (
        <Drawer
          classes={{ paper: classes.desktopDrawer }}
          variant="persistent"
          anchor="left"
          open={open}
        >
          {content}
        </Drawer>
      ) : (
        <Drawer
          classes={{ paper: classes.mobileDrawer }}
          variant="persistent"
          anchor="left"
          open={true}
        >
          {content}
        </Drawer>
      )}
    </div>
  );
};

TopBar.propTypes = {
  className: PropTypes.string,
  onMobileNavOpen: PropTypes.func
};

TopBar.defaultProps = {
  onMobileNavOpen: () => {}
};

export default TopBar;
